import { sync } from 'vuex-pathify';

export default {
  data() {
    return {
      skins: [
        { url: '/webamp/skins/AmigaPPC-dark.wsz', name: 'AmigaPPC Dark' }, //
        { url: '/webamp/skins/Green-Dimension-V2.wsz', name: 'Green Dimension' },
        { url: '/webamp/skins/Internet-Archive.wsz', name: 'Internet Archive' },
        { url: '/webamp/skins/MacOSXAqua1-5.wsz', name: 'Mac OSX Aqua' },
        { url: '/webamp/skins/Skinner_Atlas.wsz', name: 'Skinner Atlas' },
        { url: '/webamp/skins/Sonic_Attitude.wsz', name: 'Sonic Attitude' },
        { url: '/webamp/skins/TopazAmp1-2.wsz', name: 'Topaz Amp' },
        { url: '/webamp/skins/Vizor1-01.wsz', name: 'Vizor' },
        { url: '/webamp/skins/Vortigo_3D.wsz', name: 'Vortigo_3D' },
        { url: '/webamp/skins/XMMS-Turquoise.wsz', name: 'XMMS Turquoise' },
        { url: '/webamp/skins/ZaxonRemake1-0.wsz', name: 'Zaxon Remake' },
        { url: '/webamp/skins/zeldaamp_3v2.wsz', name: 'Zelda' },
      ],
    };
  },

  computed: {
    ...sync([
      'webamp/skin', //
    ]),

    initialSkin() {
      return this.$route.query.skinUrl
        ? {
          url: this.$route.query.skinUrl,
          name: 'skinUrl',
        }
        : this.skin;
    },
  },
};
