<template>
  <div>
    <div
      v-if="playButton"
      class="tw-fixed tw-inset-0 tw-z-30 tw-flex tw-items-center tw-w-full tw-bg-white"
    >
      <div class="tw-max-w-lg tw-m-auto tw-text-center">
        <button
          class="tw-px-4 tw-py-2 tw-font-bold tw-text-white tw-bg-purple-500 tw-rounded hover:tw-bg-purple-600 focus:tw-outline-none focus:tw-shadow-outline"
          @click="play"
        >
          <i class="fas fa-redo-alt" /> JOIN DISCO!
        </button>
      </div>
    </div>
    <div
      id="winamp-container"
      @click="toggleOverlay"
      @dblclick="toggleMilkdrop"
    />
  </div>
</template>

<script>
/* eslint-disable default-case */

import Webamp from 'webamp';
import butterchurnPresets from 'butterchurn-presets';

import { sync } from 'vuex-pathify';

import skins from '@/mixins/webamp/skins';
import keyboard from '@/mixins/webamp/keyboard';
import observers from '@/mixins/webamp/observers';

export default {
  mixins: [
    skins, //
    keyboard, //
    observers, //
  ],

  props: {
    initialSongs: {
      type: Array,
      default: () => [],
    },

    config: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      playButton: false,
      webamp: null,
      // songs: [],
      playing: false,
      milkdrop: true,
      offset: {
        x: 0,
        y: 0,
      },
    };
  },

  computed: {
    ...sync([
      'device/device', //
      'webamp/song', //
      'webamp/songs', //
      'webamp/options', //
    ]),
  },

  mounted() {
    this.init();
    this.registerBusListeners();
  },

  beforeDestroy() {
    this.webamp.dispose();
    Bus.$off('mode:double');
    Bus.$off('track:seek-to');
    Bus.$off('player:set-volume');
    Bus.$off('songs:fetched');
    Bus.$off('skin:changed');
    Bus.$off('player:press-button');
  },

  methods: {
    init() {
      if (!Webamp.browserIsSupported()) {
        console.log('Oh no! Webamp does not work!');
        throw new Error("What's the point of anything?");
      }

      let initialWindowLayout;

      if (this.config.show.eq) {
        initialWindowLayout = {
          main: { position: { x: this.offset.x + 0, y: this.offset.y + 0 } },
          equalizer: { position: { x: this.offset.x + 0, y: this.offset.y + 116 } },
          playlist: { position: { x: this.offset.x + 0, y: this.offset.y + 232 }, size: [0, 8] },
          // milkdrop: { position: { x: this.offset.x + 275, y: this.offset.y + 0 }, size: [7, 12] },
        };
      } else {
        initialWindowLayout = {
          main: { position: { x: this.offset.x + 0, y: this.offset.y + 0 } },
          playlist: { position: { x: this.offset.x + 0, y: this.offset.y + 116 }, size: [0, 12] },
        };
      }

      const config = {
        initialTracks: this.initialSongs,
        initialSkin: this.initialSkin,
        availableSkins: this.skins,
        // filePickers: [dropboxFilePicker],
        enableHotkeys: true,
        __initialWindowLayout: initialWindowLayout,
      };

      if (this.config.show.milkdrop && this.device.bowser.browser.name !== 'Safari') {
        config.__butterchurnOptions = {
          importButterchurn: () => import('butterchurn'),
          getPresets: () => {
            const presets = butterchurnPresets.getPresets();
            return Object.keys(presets).map((name) => ({
              name,
              butterchurnPresetObject: presets[name],
            }));
          },
          butterchurnOpen: true,
        };
      }

      this.webamp = new Webamp(config);

      if (this.config.show.playlist === false) {
        this.webamp.store.dispatch({ type: 'CLOSE_WINDOW', windowId: 'playlist' });
      }

      if (this.config.show.eq === false) {
        this.webamp.store.dispatch({ type: 'CLOSE_WINDOW', windowId: 'equalizer' });
      }

      this.webamp.store.dispatch({ type: 'SET_VOLUME', volume: 80 });

      // toggle visualizer twice (set to blank)
      this.webamp.store.dispatch({ type: 'TOGGLE_VISUALIZER_STYLE' });
      this.webamp.store.dispatch({ type: 'TOGGLE_VISUALIZER_STYLE' });

      // this.webamp.store.dispatch({ type: 'TOGGLE_DOUBLESIZE_MODE' });

      this.webamp.renderWhenReady(document.getElementById('winamp-container')).then(() => {
        // console.log('webamp rendered!');

        if (this.isDJ()) {
          this.registerDjObservers();
        }
      });

      this.webamp.play();

      if (this.options.startInDesktopMode) {
        this.webamp.store.dispatch({ type: 'SET_MILKDROP_DESKTOP', enabled: true });
      }

      this.webamp.onTrackDidChange((song) => {
        if (song) {
          this.song = this.songs.filter((value) => value.metaData.title === song.metaData.title)[0];

          if (this.isDJ()) {
            this.broadcastTrack(this.webamp.store.getState());
          }
          // console.log('New song playing:', song.metaData);
          switch (true) {
            case song.metaData.artist !== null && song.metaData.title !== null:
              this.$store.set('meta/title', `${song.metaData.artist} - ${song.metaData.title}`);
              break;

            case song.metaData.artist !== null && song.metaData.title === null:
              this.$store.set('meta/title', `${song.metaData.artist}`);
              break;

            case song.metaData.artist === null && song.metaData.title !== null:
              this.$store.set('meta/title', `${song.metaData.title}`);
              break;

            default:
              this.$store.set('meta/title', 'invalid metaData');
              break;
          }
        }
      });

      setInterval(() => {
        const state = this.webamp.store.getState();

        if (state.media.status === 'PLAYING') {
          if (this.isDJ()) {
            this.broadcastTrack(state);
          }

          // console.log('media', state.media);
          // console.log('playlist', state.playlist);
          // console.log('tracks', state.tracks);
          // console.log(`Currently playing track ${state.playlist.currentTrack + 1} of ${state.playlist.trackOrder.length} (${state.tracks[state.playlist.currentTrack].title}) at ${state.media.timeElapsed}`);
          // if (this.$auth.check()) {
          // window.Echo.private(`online`).whisper('reporting', {
          //   info: `Currently playing track ${state.playlist.currentTrack + 1} of ${state.playlist.trackOrder.length} (${state.tracks[state.playlist.currentTrack].title}) at ${state.media.timeElapsed}`,
          // });
          // }
        }
      }, 5000);
    },

    play() {
      this.playButton = false;
      this.webamp.play();
    },

    toggleOverlay() {
      Bus.$emit('idle:toggle');
    },

    toggleMilkdrop() {
      this.milkdrop = !this.milkdrop;

      this.webamp.store.dispatch({ type: 'ENABLE_MILKDROP', enabled: this.milkdrop });

      if (this.milkdrop) {
        this.webamp.store.dispatch({ type: 'SET_MILKDROP_DESKTOP', enabled: true });
      }
    },

    registerBusListeners() {
      Bus.$on('music:auto-sync', (e) => {
        // console.log('music:auto-sync', e);

        const state = this.webamp.store.getState();

        if (state.media.status === 'PLAYING' && state.media.timeElapsed === 0) {
          // must prompt user to press play
          this.playButton = true;
        } else {
          this.playButton = false;
          // determine if user is playing the same song as dj
          if (state.tracks[state.playlist.currentTrack].title !== e.track.title) {
            const trackId = Object.keys(state.tracks).filter((index) => {
              if (state.tracks[index].title === e.track.title) {
                return true;
              }
            });

            Bus.$emit('track:play', trackId);
          }

          // determine if user is out of sync
          const diff = Math.abs(state.media.timeElapsed - e.seekTime);

          // const syncTolerance = 0.1
          const syncTolerance = 0.5;

          // console.log(diff);

          if (diff >= syncTolerance) {
            // console.log('syncing music');

            this.webamp.seekToTime(e.seekTime);

            // if (state.media.timeElapsed > e.seekTime) {
            //   this.webamp.seekToTime(e.seekTime - 0.17);
            // }
            // else {
            //   this.webamp.seekToTime(e.seekTime + 0.17);
            // }
          }
        }
      });

      Bus.$on('visualizer:style', () => {
        // console.log(`playing track ${id}`);
        this.webamp.store.dispatch({ type: 'TOGGLE_VISUALIZER_STYLE' });
      });

      Bus.$on('mode:double', () => {
        // console.log(`playing track ${id}`);
        this.webamp.store.dispatch({ type: 'TOGGLE_DOUBLESIZE_MODE' });
      });

      Bus.$on('track:play', (id) => {
        // console.log(`playing track ${id}`);
        this.webamp.store.dispatch({ type: 'PLAY_TRACK', id });
      });

      Bus.$on('track:seek-to', (seekto) => {
        this.webamp.seekToTime(seekto);
      });

      Bus.$on('player:set-volume', (volume) => {
        this.webamp.store.dispatch({ type: 'SET_VOLUME', volume });
      });

      Bus.$on('player:press-button', (button) => {
        switch (button) {
          case 'play':
            this.webamp.play();
            break;

          case 'pause':
            this.webamp.pause();
            break;

          case 'stop':
            this.webamp.stop();
            break;

          case 'prev':
            this.webamp.previousTrack();
            break;

          case 'next':
            this.webamp.nextTrack();
            break;
        }
      });

      Bus.$on('songs:fetched', (songs) => {
        // console.log('songs:fetched', songs);

        this.songs = songs;

        this.webamp.setTracksToPlay(this.songs);

        this.webamp.play();
      });

      Bus.$on('skin:changed', (skin) => {
        console.log('skin:changed', skin);
        // this.webamp.store.dispatch({ type: 'SET_SKIN_DATA', skin });
        this.webamp.setSkinFromUrl(skin.url);
        // this.webamp.dispose();
        // this.init();
      });
    },
  },
};
</script>
