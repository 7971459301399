import { sync } from 'vuex-pathify';

export default {
  computed: {
    ...sync([
      'api/params', //
    ]),
  },

  methods: {
    broadcastButton(button) {
      // console.log(`all clients seeking to ${seekTime}`);

      const broadcastData = {
        action: 'button',
        button: button,
      };

      window.Echo.private(`room-1`).whisper('dj', broadcastData);
    },

    broadcastSeek(seekTime) {
      // console.log(`all clients seeking to ${seekTime}`);

      const broadcastData = {
        action: 'seek',
        seekTime: seekTime,
      };

      window.Echo.private(`room-1`).whisper('dj', broadcastData);
    },

    broadcastVolume(volume) {
      // console.log(`all clients seeking to ${seekTime}`);

      const broadcastData = {
        action: 'volume',
        volume: volume,
      };

      window.Echo.private(`room-1`).whisper('dj', broadcastData);
    },

    broadcastTrack(state) {
      // console.log(`all clients seeking to ${seekTime}`);

      // window.Echo.private(`online`).whisper('reporting', {
      //   info: `Currently playing track ${state.playlist.currentTrack + 1} of ${state.playlist.trackOrder.length} (${state.tracks[state.playlist.currentTrack].title}) at ${state.media.timeElapsed}`,
      // });  

      if(typeof state === 'undefined') {
        return true;
      }
      
      // console.log(state.tracks);

      const broadcastData = {
        action: 'track',
        track: state.tracks[state.playlist.currentTrack],
        tracks: state.tracks,
        seekTime: state.media.timeElapsed,
        apiParams: this.params,
      };

      // console.log('broadcastData', broadcastData);

      window.Echo.private(`room-1`).whisper('dj', broadcastData);
    },

    registerDjObservers() {
      // console.log('registerDjObservers');
      
      this.registerStateObservers();
      this.registerActionEmitters();
      // this.registerMutationObservers();
    },

    registerStateObservers() {
      const store = this.webamp.store;

      // Trigger a callback every time we either start or stop playing
      this.onStateChange(store, this.isPlaying, (playing) => {
        if (playing) {
          // console.log('We just started playing');
          this.broadcastButton('play');
        } else {
          // console.log('We just stopped playing');
          this.broadcastButton('pause');
        }
      });

      // Trigger a callback every time we either start or stop playing
      this.onStateChange(store, this.getTimeElapsed, (elapsed) => {
        // console.log(`Notify other clients that the new elapsed time is ${elapsed}`);
      });

      this.onStateChange(store, this.getVolume, (volume) => {
        // console.log(`Notify other clients that the new volume is ${volume}`);
        this.broadcastVolume(volume);
      });
    },

    registerActionEmitters() {
      // this.webamp._actionEmitter.on('UPDATE_TIME_ELAPSED', (action) => {
      //   console.log(`The current time is ${action.elapsed}`);
      // });

      this.webamp._actionEmitter.on('SEEK_TO_PERCENT_COMPLETE', (action) => {
        console.log(`onSeek`, action);

        const state = this.webamp.store.getState();

        // console.log(this.getDuration(state));

        const seekTime = this.getDuration(state) * (action.percent / 100);      

        this.broadcastSeek(seekTime);
      });
    },

    registerMutationObservers() {
      /**
       * watch time
       */
      // const timeObserver = new MutationObserver((mutationRecord) => {
      //   console.log('time has changed');
      // });

      // timeObserver.observe(document.getElementById('second-second-digit'), {
      //   attributeFilter: ['class'],
      // });

      /**
       * watch seek bar
       */
      const seekObserver = new MutationObserver((mutationRecord) => {
        if (mutationRecord[0].oldValue.length < 6 && mutationRecord[0].target.getAttribute('value').length > 6) {
          const state = this.webamp.store.getState();
          const seekTime = state.media.timeElapsed;
          this.broadcastSeek(seekTime);
        }
      });

      seekObserver.observe(document.getElementById('position'), {
        attributeOldValue: true,
        attributeFilter: ['value'],
      });
    },

    onStateChange(store, predicate, on) {
      let matches = predicate(store.getState());
      return store.subscribe(() => {
        const newMatches = predicate(store.getState());
        if (newMatches !== matches) {
          on(newMatches);
        }
        matches = newMatches;
      });
    },

    isPlaying(state) {
      return state.media.status === 'PLAYING';
    },

    getTimeElapsed(state) {
      return state.media.timeElapsed;
    }, 

    getVolume(state) {
      return state.media.volume;
    }, 

    getDuration(state) {
      const { playlist, tracks } = state;
      if (playlist.currentTrack == null) {
        return null;
      }
      const currentTrack = tracks[playlist.currentTrack];
      return currentTrack && currentTrack.duration;
    },
  },
};
