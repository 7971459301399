/* eslint-disable consistent-return */
/* eslint-disable default-case */

import Keypress from 'keypress.js';
import { sync } from 'vuex-pathify';

export default {

  data() {
    return {
      listener: null,
    };
  },

  computed: {
    ...sync([

    ]),
  },

  mounted() {
    this.setupKeyboardListeners();
  },

  methods: {
    setupKeyboardListeners() {
      this.listener = new Keypress.Listener();

      this.listener.simple_combo('space', (event) => {
        if ((event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA')) {
          return true;
        }

        Bus.$emit('player:press-button', 'pause');
      });

      /**
       * seeks to time in song
       */
      this.listener.simple_combo('k', (event) => {
        if ((event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA')) {
          return true;
        }

        this.webamp.seekToTime(15.5);
      });

      /**
       * loads and plays specific song
       */
      this.listener.simple_combo('s', (event) => {
        if ((event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA')) {
          return true;
        }

        Bus.$emit('songs:fetch', {
          id: 1184, // Incubus Drive
          medium: '',
        });
      });

      /**
       * loads and plays specific playlist
       */
      this.listener.simple_combo('p', (event) => {
        if ((event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA')) {
          return true;
        }

        Bus.$emit('songs:fetch', {
          playlist: 'majestic casual/Majestic Casual',
        });
      });

      /**
       * loads and plays specific track id
       */
      this.listener.simple_combo('n', (event) => {
        if ((event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA')) {
          return true;
        }

        Bus.$emit('track:play', 3);
      });

      /**
       * loads and plays specific track id
       */
      this.listener.simple_combo('v', (event) => {
        if ((event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA')) {
          return true;
        }

        Bus.$emit('visualizer:style', 3);
      });
    },
  },

  beforeDestroy() {
    this.listener.reset();
  },
};
